import React from "react";
import { emUrl } from "../src/util";
import { BaseEventEntity, ExtraCategoryEntity } from "../src/entities";
import { serverSideFetch } from "../src/serverSideFetch";
import { Startpage } from "../src/Startpage";
import { myStaticMiddleWare } from "../src/server/middleware";
import { GetStaticProps } from "next";
import { PHASE_PRODUCTION_BUILD } from "next/constants";
import { withDefaultLayout } from "../src/layout/layout";

type Props = {
  events: BaseEventEntity[];
  extraCategories: ExtraCategoryEntity[];
};

export default withDefaultLayout(function Events(props: Props) {
  const { events, extraCategories } = props;
  return (
    <Startpage
      events={events}
      category={null}
      extraCategories={extraCategories}
    />
  );
});

export const getStaticProps: GetStaticProps = myStaticMiddleWare<Props>(
  async (ctx) => {
    const {
      data: { events, extraCategories },
      status,
    } =
      process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD
        ? // FIXME: HACK - Do not call the API at build time.
          // This is similar to the following, which is not supported on routes without parameters:
          //
          // export const getStaticPaths: GetStaticPaths = async () => {
          //   return {
          //     paths: [],
          //     fallback: "blocking",
          //   };
          // };
          { data: { events: [], extraCategories: [] }, status: 200 }
        : await serverSideFetch(ctx, emUrl("user", "main", {}, true));

    if (status !== 200) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        events,
        extraCategories,
      },
      revalidate: 3,
    };
  }
);
